<template>
  <div class="VacSection">
    <div>
      <div style="width: 100%; ">
        <b-form-group
          id="input-group-8"
          :label="$t('EditY')"
          label-for="input-25"
          style="width: 150px; margin-bottom: 20px; width: 11%;"
        >
          <vue-select
            id="input-5"
            v-model="year"
            required
            :clearable="false"
            style="
            margin-right: 10px;
            color: #82868c;
            border-radius: 8px;
            border: none;
            box-shadow: 0px 0px 10px rgb(40 41 61 / 14%);"
            :options="yearsArray"
            :placeholder="$t('Select')"
            aria-describedby="input-1-live-feedback"
            @input="handleInput"
          />
        </b-form-group>
        <div>
          <ul style="padding: 0; border-top: 1px solid gray">
            <li
              v-for="store in getFiliales"
              :key="store.storeId"
              class="pm-li"
            >
              <div style="display: flex; justify-content: space-between; align-items:flex-end">
                <h5 style="color:#262E6C; font-weight: bold; font-size: 20px;">
                  {{ $t('EmployeeFiliale') }}: {{ store.storeName }}
                </h5>
                <button
                  class="button"
                  type="button"
                  :disabled="store.length == 0"
                  @click="toggleEmployees(store.storeId)"
                >
                  {{ isStoreActive(store.storeId) ? $t('Hide') : $t('Show') }}</button>
              </div>
              <ul
                class="ul-order"
                style="margin-top: 20px;"
              >
                <div
                  v-if="getStoreVacationsOverview.length !== 0"
                  v-show="isStoreActive(store.storeId)"
                  class="order-header"
                >

                  <p
                    v-b-tooltip.focus
                    href="#"
                    tabindex="0"
                    :title="$t('ExchangeEmployeesFullName')"
                    style="width: 15%; margin-bottom: 0px;text-align: left;"
                  >
                    {{ $t('FullName') }}
                  </p>
                  <p
                    v-b-tooltip.focus
                    style="width: 20%; margin-bottom: 0px; text-align: left;"
                    href="#"
                    tabindex="0"
                    :title="$t('Vacation')"
                  >
                    {{ $t('Vacation') }}
                  </p>
                  <p
                    v-b-tooltip.focus
                    style="width: 20%; margin-bottom: 0px; text-align: left;"
                    href="#"
                    tabindex="0"
                    :title="$t('Appoitment')"
                  >
                    {{ $t('Appoitment2') }}
                  </p>
                  <p
                    v-b-tooltip.focus
                    style="width: 10%; margin-bottom: 0px; text-align: left;"
                    href="#"
                    tabindex="0"
                    :title="$t('PaidLeave')"
                  >
                    {{ $t('PaidLeave2') }}
                  </p>
                  <p
                    v-b-tooltip.click
                    style="width: 20%; margin-bottom: 0px; text-align: left;"
                    href="#"
                    tabindex="0"
                    :title="$t('AddNew46')"
                  >
                    {{ $t('DeathLeave2') }}
                  </p>
                  <p
                    v-b-tooltip.focus
                    style="width: 20%; margin-bottom: 0px; text-align: left;"
                    href="#"
                    tabindex="0"
                    :title="$t('AddNew23')"
                  >
                    {{ $t('Maternity2') }}
                  </p>
                  <p
                    v-b-tooltip.focus
                    style="width: 20%; margin-bottom: 0px; text-align: left;"
                    href="#"
                    tabindex="0"
                    :title="$t('AddNew22')"
                  >
                    {{ $t('SpecialLeave2') }}
                  </p>
                  <p
                    v-b-tooltip.focus
                    style="width: 20%; margin-bottom: 0px; text-align: left;"
                    href="#"
                    tabindex="0"
                    :title="$t('AddNew21')"
                  >
                    {{ $t('Education2') }}
                  </p>
                  <p
                    v-b-tooltip.focus
                    style="width: 20%; margin-bottom: 0px; text-align: left;"
                    href="#"
                    tabindex="0"
                    :title="$t('AddNew20')"
                  >
                    {{ $t('AccidentLeave2') }}
                  </p>
                  <p
                    v-b-tooltip.focus
                    style="width: 20%; margin-bottom: 0px; text-align: left;"
                    href="#"
                    tabindex="0"
                    :title="$t('AddNew19')"
                  >
                    {{ $t('SickLeave2') }}
                  </p>
                  <p
                    v-b-tooltip.focus
                    style="width: 20%; margin-bottom: 0px; text-align: left;"
                    href="#"
                    tabindex="0"
                    :title="$t('TotalCancelled')"
                  >
                    {{ $t('TotalCancelled2') }}
                  </p>
                  <p
                    v-b-tooltip.focus
                    style="width: 20%; margin-bottom: 0px; text-align: left;"
                    href="#"
                    tabindex="0"
                    :title="$t('TotalRemaining')"
                  >
                    {{ $t('TotalRemainin2') }}
                  </p>
                  <p
                    v-b-tooltip.focus
                    style="width: 20%; margin-bottom: 0px; text-align: left;"
                    href="#"
                    tabindex="0"
                    :title="$t('TotalSpendings')"
                  >
                    {{ $t('TotalSpendings2') }}
                  </p>
                  <p
                    v-b-tooltip.focus
                    style="width: 20%; margin-bottom: 0px; text-align: left;"
                    href="#"
                    tabindex="0"
                    title="Total"
                  >
                    {{ $t('Total') }}
                  </p>
                </div>
                <li
                  v-for="itemd in getStoreVacationsOverview"
                  v-show="isStoreActive(store.storeId)"
                  :key="itemd.id"
                >
                  <div class="order-list">
                    <div
                      class="order-card"
                      style="display: flex; align-items: center"
                    >
                      <p style="width: 15%; text-align: left;">
                        {{ itemd.fullName }}
                      </p>
                      <p style="width: 13%; text-align: left; padding-left: 15px;">
                        {{ itemd.vacation }}
                      </p>
                      <p style="width: 5%; text-align: left;">
                        {{ itemd.appointment }}
                      </p>
                      <p style="width: 13%; text-align: left;">
                        {{ itemd.paidLeave }}
                      </p>
                      <p style="width: 10%; text-align: left;">
                        {{ itemd.deathCase }}
                      </p>
                      <p style="width: 15%; text-align: left; padding-left: 20px;">
                        {{ itemd.maternity }}
                      </p>
                      <p style="width: 15%; text-align: left;">
                        {{ itemd.specialLeave }}
                      </p>
                      <p style="width: 15%; text-align: left;">
                        {{ itemd.education }}
                      </p>
                      <p style="width: 15%; text-align: left;">
                        {{ itemd.accidentLeave }}
                      </p>
                      <p style="width: 15%; text-align: left;">
                        {{ itemd.sickLeave }}
                      </p>
                      <p style="width: 15%; text-align: left;">
                        {{ itemd.totalCancelled }}
                      </p>
                      <p style="width: 15%; text-align: left;">
                        {{ itemd.totalRemaining }}
                      </p>
                      <p style="width: 15%; text-align: left;">
                        {{ itemd.totalSpent }}
                      </p>
                      <p style="width: 15%; text-align: left;">
                        {{ itemd.total }}
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div></template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { VBTooltip } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      activeStores: [], // Array to store the IDs of active stores
      yearsArray: [],
      year: new Date().getFullYear(), // Set the initial year to the current year
    }
  },
  computed: {
    ...mapGetters(['getStoresForDropdown', 'getStoreVacationsOverview', 'getFiliales']),
  },
  watch: {
    year() {
      this.fetchEmployees();
    },
  },
  mounted() {
    // this.changeLoadingtoTrue(false);


    this.loadFilialePagination({
      status: true,
      storeName: null,
      pageNumber: 1,
      pageSize: 100,
    }).then(() => {
      // this.changeLoadingtoTrue(true);
    });
    // this.changeLoadingtoTrue(false);


    const currentYear = new Date().getFullYear();

    // Create an array with the last year, current year, and next 5 years
    for (let i = 0; i <= 5; i++) {
      this.yearsArray.push(currentYear - i);
    }

    // Set the selected year to the current year
    this.year = currentYear;

    // Fetch employees for the active stores when the component is mounted
    this.fetchEmployees();
  },
  methods: {
    ...mapActions(['stores_for_Dropdown', 'store_vacations_Overview', 'loadFilialePagination', 'changeLoadingtoTrue', 'reset_store_vacations_Overview']),
    // toggleEmployees(storeId) {
    //   if (this.isStoreActive(storeId)) {
    //     this.activeStores = this.activeStores.filter((id) => { return id !== storeId }); // Remove the store from the activeStores array
    //   } else {
    //     this.activeStores.push(storeId); // Add the store to the activeStores array
    //   }
    //   this.fetchEmployees(); // Fetch the employees for the active stores
    // },
    toggleEmployees(storeId) {
      if (this.isStoreActive(storeId)) {
        // If the clicked store is already active, remove it from the activeStores array
        this.activeStores = this.activeStores.filter((id) => { return id !== storeId });
      } else {
        // If the clicked store is not active, set the activeStores array to contain only the clicked store
        this.activeStores = [storeId];
      }
      this.reset_store_vacations_Overview();
      // Fetch the employees for the active stores
      this.fetchEmployees();
    },
    isStoreActive(storeId) {
      return this.activeStores.includes(storeId); // Check if a store is active
    },
    fetchEmployees() {
      // Fetch employees for the active stores
      this.activeStores.forEach(async (storeId) => {
        await this.store_vacations_Overview({
          storeId,
          year: this.year,
        });
      });
    },
    handleInput() {
      this.year = Math.floor(this.year);

      if (this.year < 1000) {
        this.year = 1000;
      } else if (this.year > 9999) {
        this.year = 9999;
      }
    },
  },
}
</script>


<style scoped>
.hr{
  background-color: grey;
min-width: 201px;
height: 1px;
/* UI Properties */
border: 0.5px solid #A5A5A5;
opacity: 0.34;
}
.material-info:last-child {
  margin-right: 0 !important;
}
.pm-li {
  cursor:pointer;
  list-style: none;
  background: #ffffff;
  overflow-y: auto;
  border-radius: 8px;
  border: 1px solid transparent;
  box-shadow: 0px 7px 20px rgb(40 41 61 / 14%);
  padding: 12px 10px;
  margin-top: 20px;
}
.pm-li h5 {
  margin-bottom: 0;
}
.ul-order {
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  cursor:default;
}
.ul-order li {
  background: #ffffff;
  overflow-y: auto;

  border-radius: 8px;
  border: 1px solid transparent;
  box-shadow: 0px 7px 20px rgb(40 41 61 / 14%);
  padding: 6px 2px 6px 10px;
  margin-top: 15px;
  color:#262E6C ; font-weight: 600;font-size: 15px;
}
.ul-order ul {
  padding-left: 5px;
}

.order-card {
  cursor:default;
  display: flex;
}

.order-card p {
  margin-right: 20px;
  color:black ; font-weight: 500;font-size: 15px;
  word-break: break-word;
}
.stage-card {
  background: #ffffff;
  border-radius: 4px;
  /* border: 1px solid transparent; */
  box-shadow: 0px 7px 20px rgb(40 41 61 / 14%);
  padding: 10px;
  margin-bottom: 15px;
  margin-right: 10px;
  cursor: pointer;
}
.stage-card:hover {
 background: #e1ffe1;
}
.stage-card.active {
  background: transparent linear-gradient(52deg, #262E6C 0%, #9B2A5C 100%, #FF274F 100%) 0% 0% no-repeat padding-box;
  color: #f4f1ed;
  border-color: black;
}
.stage-card.activee {
  background-color: grey;
  opacity: 0.7;
  color: #b6ffb6;
  border-top: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-image-source: linear-gradient(to left, #15E582  70% , #424242 );
}
.stage-card.failed {
  background: transparent linear-gradient(52deg, #262E6C 0%, #9B2A5C 100%, #FF274F 100%) 0% 0% no-repeat padding-box;
  opacity: 0.7;
  color: #ffffff;
  border-top:2px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-image-source: linear-gradient(to left, #e51515  70% , #424242 );
}
.order-header{
  display: flex;
  font: normal normal bold 15px/19px;
  font-weight: bold;
  font-size: 15px;
}
.order-header{
  color:#262E6C ; font-weight: bold;font-size: 15px;
}

.VacSection{
  padding: 10px 15px 15px 110px;
  background-color: transparent
}

 @media screen and (max-width: 1190px) {
  .main {
    padding: 116px 0.75rem 0 0.75rem !important;
    /* justify-content: center; */
  }
 }

@media screen and (max-width: 1041px) {
    .VacSection{
      padding: 10px 15px 15px 15px;
    }
  }

@media screen and (max-width: 1500px){
  .VacSection{
      padding: 10px 35px 15px 140px;
    }
  }
</style>
