<template>
  <section
    class=" CalendarSection"
  >
    <div
      style="text-align: left;
    font-size: 50px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 20px;"
    >
      <p style="font-size: 32px; font-weight: bold; border-bottom: 1px solid lightgray;">
        {{ $t("VacationPlan") }}
      </p>
    </div>
    <div
      style="width: 100%;
      height: 1px;
      opacity: 1; margin-bottom: 30px;"
    />
    <div>
      <div
        style="display: flex;
        flex-direction: row;
        width: 74%;
        text-align: left;
        justify-content: center !important;
        margin-left: 212px;"
      >
        <div
          v-b-tooltip.focus.top
          title="Click here to hide this vacation from Calendar"
          :class="{ 'selected-filter': selectedFilters.includes('Vacation')}"
          style="
            font-weight: 600;
            background-color: red;
            color: black;
            text-align: left;
            flex: 1;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          "

          @click="setFilter('Vacation')"
        >
          {{ $t("Vacation") }}
        </div>
        <div
          v-b-tooltip.focus.top
          title="Click here to hide this vacation from Calendar"
          :class="{ 'selected-filter': selectedFilters.includes('SickLeave')}"
          style="
            font-weight: 600;
            background-color: lightblue;
            color: black;
            text-align: left;
            flex: 1;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

          "
          @click="setFilter('SickLeave')"
        >
          {{ $t("AddNew19") }}
        </div>
        <div
          v-b-tooltip.focus.top
          title="Click here to hide this vacation from Calendar"
          :class="{ 'selected-filter': selectedFilters.includes('SpecialLeave')}"
          style="
            font-weight: 600;
            background-color: yellow;
            color: black;
            text-align: left;
            flex: 1;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

          "
          @click="setFilter('SpecialLeave')"
        >
          {{ $t("AddNew22") }}
        </div>
        <div
          v-b-tooltip.focus.top
          title="Click here to hide this vacation from Calendar"
          :class="{ 'selected-filter': selectedFilters.includes('AccidentLeave') }"
          style="
            font-weight: 600;
            background-color: royalblue;
            color: black;
            text-align: left;
            flex: 1;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

          "
          @click="setFilter('AccidentLeave')"
        >
          {{ $t("AddNew20") }}
        </div>
        <div
          v-b-tooltip.focus.top
          title="Click here to hide this vacation from Calendar"
          :class="{ 'selected-filter': selectedFilters.includes('Education') }"
          style="
            font-weight: 600;
            background-color: aqua;
            color: black;
            text-align: left;
            flex: 1;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

          "
          @click="setFilter('Education')"
        >
          {{ $t("AddNew21") }}
        </div>
        <div
          v-b-tooltip.focus.top
          title="Click here to hide this vacation from Calendar"
          :class="{ 'selected-filter': selectedFilters.includes('Maternity') }"
          style="
            font-weight: 600;
            background-color: violet;
            color: black;
            text-align: left;
            flex: 1;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

          "
          @click="setFilter('Maternity')"
        >
          {{ $t("AddNew23") }}
        </div>
        <div
          v-b-tooltip.focus.top
          title="Click here to hide this vacation from Calendar"
          :class="{ 'selected-filter': selectedFilters.includes('Appointment') }"
          style="
            font-weight: 600;
            background-color: green;
            color: black;
            text-align: left;
            flex: 1;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

          "
          @click="setFilter('Appointment')"
        >
          {{ $t("AddNew24") }}
        </div>
        <div
          v-b-tooltip.focus.top
          title="Click here to hide this vacation from Calendar"
          :class="{ 'selected-filter': selectedFilters.includes('DeathCase') }"
          style="
            font-weight: 600;
            background-color: blanchedalmond;
            color: black;
            text-align: left;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

          "
          @click="setFilter('DeathCase')"
        >
          {{ $t("AddNew46") }}
        </div>
      </div>


      <div class="custom-calendar">
        <FullCalendar
          :options="calendarOptions"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import moment from 'moment'


export default {
  components: {
    FullCalendar,
  },
  data() {
    return {
      titleOfCalendar: '',
      nextDayClicked: false,
      nextMonthClicked: false,
      currentYear: moment().format('YYYY-MM-DD'),
      selectedFilters: [],
      currentFilter: 'All',
      checkStatus: false,
      isSingleDayEvent: false,
      calendarOptions: {
        firstDay: 1,
        eventContent(info) {
          return {
            html: `
            <div>
        <div class="tooltip-container">
          <div class="tooltip-trigger">${info.event.title}</div>
          <div class="tooltip">${info.event.extendedProps.description}</div>
        </div>
      </div>
      <style>
      .tooltip-container {
          position: relative;
          display: inline-block;
        }

        .tooltip-trigger {
          color: black;
          font-size: 15px;
          font-weight: 600;
          white-space: normal;
          cursor: pointer;
        }

        .tooltip {
          visibility: hidden;
          width: 200px;
          background-color: #333;
          color: #fff;
          text-align: left;
          border-radius: 6px;
          padding: 5px;
          position: absolute;
          z-index: 1;
          bottom: 125%;
          left: 50%;
          transform: translateX(-50%);
          opacity: 0;
          transition: opacity 0.3s;
        }

        .tooltip-container:hover .tooltip {
          visibility: visible;
          opacity: 1;
        }
      </style>
    `,
          };
        },
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        views: {
          week: {
            buttonText: `${this.$t('Week')}`,
            type: 'dayGridWeek',
          },
          day: {
            buttonText: `${this.$t('Daily')}`,
            type: 'dayGridDay',
          },
          month: {
            buttonText: `${this.$t('Month')}`,
            type: 'dayGridMonth',
          },
          year: {
            buttonText: 'Jahr',
            type: 'dayGridYear',
          },
        },
        headerToolbar: {
          left: 'prev,next',
          center: 'title',
          right: 'day month',
        },
        locale: 'de', // Set the locale to German (de)
        events: [], // Initialize with an empty array
        titleFormat: { year: 'numeric', month: 'short', day: 'numeric' }, // Display year and month in the title
      },
    };
  },
  computed: {
    ...mapGetters(['getCalendarVacation', 'getIsLoading']),
  },
  watch: {
    getCalendarVacation() {
      this.updateEvents();
    },
  },
  mounted() {
    // this.changeLoadingtoTrue(false);


    this.loadCalculation({
      exactDate: null,
      startDate: null,
      endDate: null,
      year: moment(this.currentYear).format('YYYY-MM-01'),
    }).then(() => {
      // this.changeLoadingtoTrue(true);
    });
    // this.changeLoadingtoTrue(false);

    const prevButton = document.querySelector('.fc-prev-button');
    const nextButton = document.querySelector('.fc-next-button');
    const nextDay = document.querySelector('.fc-day-button');
    const nextMonth = document.querySelector('.fc-month-button');

    if (prevButton) {
      prevButton.addEventListener('click', () => {
        if (this.nextDayClicked) {
          this.handlePrevClickDay();
        } else if (this.nextMonthClicked) {
          this.handlePrevClickMonth();
        } else {
          this.handlePrevClick();
        }
      });
    }

    if (nextButton) {
      nextButton.addEventListener('click', () => {
        if (this.nextDayClicked) {
          this.handleNextClickDay();
        } else if (this.nextMonthClicked) {
          this.handleNextClickMonth();
        } else {
          this.handleNextClick();
        }
      });
    }

    if (nextDay) {
      nextDay.addEventListener('click', () => {
        const calendarTitleElement = document.querySelector('.fc-toolbar-title');

        if (calendarTitleElement) {
          if (this.titleOfCalendar == '') {
            this.titleOfCalendar = calendarTitleElement.textContent.trim();
            const formattedDate = moment(this.titleOfCalendar, 'D. MMM. YYYY', 'de').format('YYYY-MM-DD');


            this.titleOfCalendar = formattedDate;
          }
        }


        this.nextDayClicked = true;
        this.nextMonthClicked = false;
      });
    }
    if (nextMonth) {
      nextMonth.addEventListener('click', () => {
        this.nextMonthClicked = true;
        this.nextDayClicked = false;
        this.currentYear = moment(this.currentYear).format('YYYY-MM-01')
        const calendarTitleElement = document.querySelector('.fc-toolbar-title');

        if (calendarTitleElement) {
          if (this.titleOfCalendar == '') {
            this.titleOfCalendar = calendarTitleElement.textContent.trim();
            const formattedDate = moment(this.titleOfCalendar, 'D. MMM. YYYY', 'de').format('YYYY-MM-DD');



            this.titleOfCalendar = formattedDate;
          }
        }
      });
    }
  },
  methods: {
    ...mapActions(['loadCalculation', 'changeLoadingtoTrue']),
    handlePrevClickDay() {
      const previousDay = moment(this.titleOfCalendar).subtract(1, 'days').format('YYYY-MM-DD');

      this.loadCalculation({
        exactDate: null,
        startDate: null,
        endDate: null,
        year: previousDay,
      }).then(() => {
      });
      this.titleOfCalendar = moment(this.titleOfCalendar).subtract(1, 'days').format('YYYY-MM-DD');
    },
    handlePrevClickMonth() {
      const previousYear = moment(this.titleOfCalendar).subtract(1, 'months').format('YYYY-MM-01');

      this.loadCalculation({
        exactDate: null,
        startDate: null,
        endDate: null,
        year: previousYear,
      }).then(() => {
      });
      this.titleOfCalendar = moment(this.titleOfCalendar).subtract(1, 'months').format('YYYY-MM-01');
    },
    handleNextClickMonth() {
      const previousYear = moment(this.titleOfCalendar).add(1, 'months').format('YYYY-MM-01');

      this.loadCalculation({
        exactDate: null,
        startDate: null,
        endDate: null,
        year: previousYear,
      }).then(() => {
      });
      this.titleOfCalendar = moment(this.titleOfCalendar).add(1, 'months').format('YYYY-MM-01');
    },
    handlePrevClick() {
      const previousYear = moment(this.currentYear).subtract(1, 'months').format('YYYY-MM-01');

      this.loadCalculation({
        exactDate: null,
        startDate: null,
        endDate: null,
        year: previousYear,
      }).then(() => {
      });
      this.currentYear = moment(this.currentYear).subtract(1, 'months').format('YYYY-MM-01');
    },
    handleNextClick() {
      const previousYear = moment(this.currentYear).add(1, 'months').format('YYYY-MM-01');

      this.loadCalculation({
        exactDate: null,
        startDate: null,
        endDate: null,
        year: previousYear,
      }).then(() => {
      });
      this.currentYear = moment(this.currentYear).add(1, 'months').format('YYYY-MM-01');
    },
    handleNextClickDay() {
      const previousYear = moment(this.titleOfCalendar).add(1, 'days').format('YYYY-MM-DD');

      this.loadCalculation({
        exactDate: null,
        startDate: null,
        endDate: null,
        year: previousYear,
      }).then(() => {
      });
      this.titleOfCalendar = moment(this.titleOfCalendar).add(1, 'days').format('YYYY-MM-DD');
    },
    setFilter(filter) {
      if (this.selectedFilters.includes(filter)) {
      // Filter already selected, so remove it
        const index = this.selectedFilters.indexOf(filter);
        this.selectedFilters.splice(index, 1);
      } else {
      // Filter not selected, so add it
        this.selectedFilters.push(filter);
      }
      this.updateEvents();
    },
    updateEvents() {
      const vacationData = this.getCalendarVacation;
      let events = [];
      if (this.selectedFilters.length === 0) {
        events = vacationData.map((vacation) => {
          let vacationType = '';
          const startDate = moment(vacation.startDate).format('DD-MM-YYYY');
          const endDate = moment(vacation.endDate).format('DD-MM-YYYY');
          let eventBackgroundColor = '';

          // Set the vacationType and eventBackgroundColor based on the vacationType
          if (vacation.vacationType === 'Vacation') {
            vacationType = this.$t('Vacation');
            eventBackgroundColor = 'red';
          } else if (vacation.vacationType === 'SickLeave') {
            vacationType = this.$t('AddNew19');
            eventBackgroundColor = 'lightblue'
          } else if (vacation.vacationType === 'SpecialLeave') {
            vacationType = this.$t('AddNew22');
            eventBackgroundColor = 'yellow';
          } else if (vacation.vacationType === 'AccidentLeave') {
            vacationType = this.$t('AddNew20');
            eventBackgroundColor = 'royalblue';
          } else if (vacation.vacationType === 'Education') {
            vacationType = this.$t('AddNew21');
            eventBackgroundColor = 'aqua';
          } else if (vacation.vacationType === 'Maternity') {
            vacationType = this.$t('AddNew23');
            eventBackgroundColor = 'violet';
          } else if (vacation.vacationType === 'Appointment') {
            vacationType = this.$t('AddNew24');
            eventBackgroundColor = 'green';
          } else if (vacation.vacationType === 'DeathCase') {
            vacationType = this.$t('AddNew46');
            eventBackgroundColor = 'blanchedalmond';
          }

          // Set the description with the vacationType
          // const description = vacationType;

          let description = ''
          if (vacationType === this.$t('AddNew22')) {
            description = `${vacationType}/Compensated Days`;
          } else {
            description = vacationType;
          }

          let title = '';
          if (vacationType === this.$t('AddNew22')) {
            title = `${vacation.fullName} (${startDate} / ${endDate}) / ${vacation.compensateDays}`;
          } else {
            title = `${vacation.fullName} (${startDate} / ${endDate})`;
          }

          return {
            description,
            title,
            start: vacation.startDate,
            end: vacation.endDate,
            color: eventBackgroundColor,
          };
        });
      } else {
        events = vacationData.filter((vacation) => { return !this.selectedFilters.includes(vacation.vacationType) }).map((vacation) => {
          let vacationType = '';
          const startDate = moment(vacation.startDate).format('DD-MM-YYYY');
          const endDate = moment(vacation.endDate).format('DD-MM-YYYY');
          let eventBackgroundColor = '';

          // Set the vacationType and eventBackgroundColor based on the vacationType
          if (vacation.vacationType === 'Vacation') {
            vacationType = this.$t('Vacation');
            eventBackgroundColor = 'red';
          } else if (vacation.vacationType === 'SickLeave') {
            vacationType = this.$t('AddNew19');
            eventBackgroundColor = 'lightblue'
          } else if (vacation.vacationType === 'SpecialLeave') {
            vacationType = this.$t('AddNew22');
            eventBackgroundColor = 'yellow';
          } else if (vacation.vacationType === 'AccidentLeave') {
            vacationType = this.$t('AddNew20');
            eventBackgroundColor = 'royalblue';
          } else if (vacation.vacationType === 'Education') {
            vacationType = this.$t('AddNew21');
            eventBackgroundColor = 'aqua';
          } else if (vacation.vacationType === 'Maternity') {
            vacationType = this.$t('AddNew23');
            eventBackgroundColor = 'violet';
          } else if (vacation.vacationType === 'Appointment') {
            vacationType = this.$t('AddNew24');
            eventBackgroundColor = 'green';
          } else if (vacation.vacationType === 'DeathCase') {
            vacationType = this.$t('AddNew46');
            eventBackgroundColor = 'blanchedalmond';
          }

          // Set the description with the vacationType
          let description = ''
          if (vacationType === this.$t('AddNew22')) {
            description = `${vacationType}/Compensated Days`;
          } else {
            description = vacationType;
          }
          let title = '';
          if (vacationType === this.$t('AddNew22')) {
            title = `${vacation.fullName} (${startDate} / ${endDate}) / ${vacation.compensateDays}`;
          } else {
            title = `${vacation.fullName} (${startDate} / ${endDate})`;
          }

          return {
            description,
            title,
            start: vacation.startDate,
            end: vacation.endDate,
            color: eventBackgroundColor,
          };
        });
      }

      this.calendarOptions.events = events;
    },

  },
};
</script>


<style>
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 23px;
}

.form-control {
  width: 100%;
  border: 1px solid #242f6e;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(191, 203, 217);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #242f6e;
}

input:focus + .slider {
  box-shadow: 0 0 1px rgb(117, 199, 145);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.custom-calendar {
  max-width: 1200px;
  /* Custom styles for the FullCalendar component */
  /* background-color: #f2f2f2; */
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 0 210px;
  margin-top: 23px;
}
a {
  color: black;
  text-decoration: none;
}
.fc-day-header {
  text-decoration: none; /* Remove underline from day of the week headers */
}

.fc-day-number {
  text-decoration: none; /* Remove underline from numbers */
}

.fc-daygrid-day-events .fc-event-main {
  /* Set event box background color to light grey */
  padding: 5px; /* Add padding to the event box */
  font-size: 14px; /* Adjust the font size of the event title */
}

.CalendarSection {
  padding: 54px 133px 15px 158px;
  padding-top: 100px;
  background-color: transparent;
}

.custom-calendar .fc-daygrid-day {
  background-color: #fff;
  border: 1px solid #eee;
  width: calc(100% / 7); /* Divide the width evenly between 7 days */
  height: 80px;
}

.custom-calendar .fc-daygrid-day-number {
  color: #333;
  font-weight: bold;
  font-size: 14px;
}

  .custom-calendar .fc-event-main {
    /* background-color: lightgrey; */
    color: #fff;
    padding: 5px;
    border-radius: 3px;
    font-size: 12px;
  }

  .types{
    display: flex;
    flex-direction: row;
    width: 74%;
    text-align: center;
    justify-content: center !important;
    margin-left: 102px;
  }

/* .custom-calendar .fc-event-main:hover {
    background-color: transparent;
    cursor: pointer;
  } */
.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: 2em;
  position: relative;
  margin-top: 35px;
}

.filter {
  font-weight: 600;
  color: black;
  text-align: center;
  flex: 1;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.selected-filter {
  position: relative;
}

.selected-filter::before {
  content: '';
  position: absolute;
  bottom: 50%;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: white;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    margin-right: 2px;
    background: lightgray;
}

@media screen and (max-width: 1041px) {
  .CalendarSection {
    padding: 100px 30px 15px 30px;
  }
}

  @media screen and (max-width: 1500px){
    .CalendarSection{
      padding: 100px 30px 15px 150px;
    }

    .types{
      width: 80%;
    }
  }

  @media screen and (min-width: 1300px) and (max-width:1400px){
    .types{
      width: 82%;
    }
  }

  @media screen and (min-width:1400px) and (max-width: 1735px){
    .types{
      width: 84%;
    }
  }
  </style>
